import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function SkeletonSmallBox() {
  return (
    <Stack spacing={1}>
      <Skeleton variant='rectangular' width={320} height={320} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm:'100'} }} />
    </Stack>
  );
}

export default SkeletonSmallBox