import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface AccountListsProps {
	userKey: string;
	email: string;
	name: string;
	isActive: boolean;
	lastLoginDate: string;
	regDate: string;
	modDate: string;
  };

interface AccountListContentsProps {
  accountLists: {
	userKey: string;
	email: string;
	name: string;
	isActive: boolean;
	lastLoginDate: string;
	regDate: string;
	modDate: string;
  }[];
  handleClickModifyAccountDialog: (userKey: string) => void;
};

function AccountListContent({accountLists, handleClickModifyAccountDialog}: AccountListContentsProps) {
  return (
    <div>
      {accountLists && accountLists.map((list: AccountListsProps) => {
        const isActiveString = (list.isActive === true) ? '사용 중' : '사용 안함';
        const licenseIcon = (list.isActive === true) ? <CheckCircleOutlineIcon color='success' className='list-margin-right' /> : <RemoveCircleOutlineIcon color='error' className='list-margin-right' />
        const lastLoginDate = (list.lastLoginDate) ? list.lastLoginDate.split(' ')[0] : '';
        const regDate = list.regDate.split(' ');
        
        return (
          <div key={list.userKey} className='list-contents' onClick={() => handleClickModifyAccountDialog(list.userKey)}>
            <div className='account-list-content-email'>{licenseIcon} {list.email}</div>
            <div className='account-list-content-name'>{list.name}</div>
            <div className='account-list-content-enable'>{isActiveString}</div>
            <div className='account-list-content-last-login'>{lastLoginDate}</div>
            <div className='account-list-content-regdate'>{regDate[0]}</div>
          </div>
        );
      })} 
    </div>
  )
}

export default AccountListContent

