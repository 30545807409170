import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BlockIcon from '@mui/icons-material/Block';
import { GetLicenseList } from '../../../libraries/License';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import RegisterLicenseDialog from '../common/RegisterLicenseDialog';
import ModifyLicenseDialog from '../common/ModifyLicenseDialog';

interface CompanyDetailLicenseListProps {
	companyKey: string
}

interface  CompanyDetailLicenseListContentsProps {
  licenseKey: string,
  companyKey: string,
  companyCode: string,
  license: string,
  domainNumber: number,
  userNumber: number,
  duplexingNumber: number,
  usePrivateFilter: boolean,
  useWinApp: boolean,
  useBackupApp: boolean,
  expireDate: string,
  enable: boolean,
  registerServer: boolean,
  registerServerIp: string,
  packageVersion: string,
  modDate: string,
  regDate: string	
}

function CompanyDetailLicenseList(companyKey: CompanyDetailLicenseListProps) {
  const [lienseList, setLicenseList] = useState([{
    licenseKey: '',
    companyKey: '',
    companyCode: '',
    license: '',
    domainNumber: 0,
    userNumber: 0,
    duplexingNumber: 0,
    usePrivateFilter: false,
    useWinApp: false,
    useBackupApp: false,
    expireDate: '',
    enable: false,
    registerServer: false,
    registerServerIp: '',
    packageVersion: '',
    modDate: '',
    regDate: ''
  }]);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [openRegisterLicenseDialog, setOpenRegisterLicenseDialog] = useState(false);
  const [openModifyLicenseDialog, setOpenModifyLicenseDialog] = useState(false);
  const [registerLicenseRefreshList, setRegisterLicenseRefreshList] = useState(false);
  const [licenseKey, setLicenseKey] = useState('');

  const handleClickRegisterLicense = () => {
    setOpenRegisterLicenseDialog(true);
  };

  const handleClickRegisterLicenseDialog = () => {
    if (openRegisterLicenseDialog === true) {
      setOpenRegisterLicenseDialog(false);
    } else {
      setOpenRegisterLicenseDialog(true);
    }
  };

  const handleClickModifyLicense = (licenseKey: string) => {
    setLicenseKey(licenseKey);
    setOpenModifyLicenseDialog(true);
  };

  const handleClickModifyLicenseDialog = () => {
    if (openModifyLicenseDialog === true) {
      setOpenModifyLicenseDialog(false);
    } else {
      setOpenModifyLicenseDialog(true);
    }
  };
  
  const refreshLicenseList = () => {
    setRegisterLicenseRefreshList(!registerLicenseRefreshList);
  }

  useEffect(() => {
    if (companyKey.companyKey) {
      GetLicenseList(companyKey).then(result => {
        setLicenseList(result.data.list);
        setCompleteLoading(true);
      }).catch(error => {
        console.log(error.request.response);
      });
    };
  },[companyKey, registerLicenseRefreshList]);

  useEffect(() => {
    return() => handleClickModifyLicenseDialog();
  },[]);

  return (
    <>
      {completeLoading !== false ? (
        <div className='detail-license-list'>
          {lienseList && lienseList.map((list: CompanyDetailLicenseListContentsProps) => {
            const disableOpacity = (list.enable === false) ? 'opacity' : '';
            const enableIcon = (list.enable === true) ?  <CheckCircleOutlineOutlinedIcon color='success'/> : <BlockIcon color='error' />
            return (
              <Paper key={list.licenseKey} elevation={1} className={`detail-license-paper ${disableOpacity}`} onClick={() => handleClickModifyLicense(list.licenseKey)}>
                <div>
                  <div className='detail-license-column'>{list.license} {enableIcon}</div>
                  <div>업체 코드 {list.companyCode}</div>
                  <div>도메인 {list.domainNumber}개</div>
                  <div>사용자 {list.userNumber}명</div>
                </div>
              </Paper>
            );
          })}
          <div className='detail-license-add' onClick={handleClickRegisterLicense}>
            <AddCircleIcon fontSize='large' color='primary'/>
          </div>
        </div>
      ) : (
        <Stack spacing={1}>
          <Skeleton variant='rectangular' height={110} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm:'100'} }} />
        </Stack>
      )}
      <RegisterLicenseDialog 
        openRegisterLicenseDialog={openRegisterLicenseDialog} 
        handleClickRegisterLicenseDialog={handleClickRegisterLicenseDialog}
        companyKey={companyKey.companyKey}
        refreshLicenseList={refreshLicenseList}
      />
      <ModifyLicenseDialog 
        openModifyLicenseDialog={openModifyLicenseDialog} 
        handleClickModifyLicenseDialog={handleClickModifyLicenseDialog}
        companyKey={companyKey.companyKey}
        licenseKey={licenseKey}
        refreshLicenseList={refreshLicenseList}
      />
    </>
  );
}

export default CompanyDetailLicenseList