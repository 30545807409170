import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionStorage } from '../../libraries/Authentication';

interface PrivateRouteProps {
  children: JSX.Element;
}

function PrivateRoute({children}: PrivateRouteProps) {
  try {
	  const accessToken = getSessionStorage();
  	return accessToken ? children : <Navigate to='/' />;
  } catch {
    return <Navigate to='/' />;
  }
}

export default PrivateRoute