import React from 'react';

function AccountListColumn() {
  return (
    <div className='list-column'>
      <div>Email</div>
      <div>이름</div>
      <div>사용 여부</div>
      <div>마지막 로그인 </div>
      <div>등록일</div>
    </div>
  );
}

export default AccountListColumn