import React from 'react';
import './scss/main.scss';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import SignIn from './pages/signin/SignIn';
import Dashboard from './pages/main/Dashboard';
import CompanyOneDepthListPage from './pages/main/CompanyOneDepthListPage';
import CompanyTwoDepthDetailPage from './pages/main/CompanyTwoDepthDetailPage';
import Account from './pages/main/Account';
import NotFound from './pages/404/NotFound';
import PrivateRoute from './components/route/PrivateRoute';
import PublicRoute from './components/route/PublicRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path='/company' element={<PrivateRoute><CompanyOneDepthListPage /></PrivateRoute>} />
        <Route path='/companydetail/:companyKey' element={<PrivateRoute><CompanyTwoDepthDetailPage /></PrivateRoute>} />
        <Route path='/account' element={<PrivateRoute><Account /></PrivateRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
  
export default App
