import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { GetActiveAndExpiredLicenseCount } from '../../libraries/Dashboard';
import SkeletonSmallBox from './common/SkeletonSmallBox';

interface resultActiveAndExpiredLicenseCountProps {
  label: string;
  count: number;
}

function ActiveAndExpiredLicenseCount() {
  const [completeLoading, setCompleteLoading] = useState(false);
  const [countValues, setCountValues] = useState({
    activeCount: 0, 
    willExpireConunt: 0, 
    expiredCount: 0
  });

  useEffect(() => {
    GetActiveAndExpiredLicenseCount().then(result => {
      const resultData = result.data;
      if (resultData.result === 'S') {
        let resultActiveCount = 0;
        let resultWillExpireCount = 0;
        let resultExpiredCount = 0;
          
        resultData.list.map((list: resultActiveAndExpiredLicenseCountProps) => {
          if (list.label === 'active') {
            resultActiveCount = list.count;
          } else if (list.label === 'willExpire') {
            resultWillExpireCount = list.count;
          } else if (list.label === 'expired') {
            resultExpiredCount = list.count;
          }
          setCompleteLoading(true);
          setCountValues({
            activeCount: resultActiveCount, 
            willExpireConunt: resultWillExpireCount, 
            expiredCount: resultExpiredCount
          });
        });
      }
    }).catch(error => {
      console.log(error);
    });
  },[]);
  
  useEffect(() => {
    return() => setCompleteLoading(true);
  },[]);

  return(
    <>
      {completeLoading !== false ? (
        <div className='dashboard-list-active'>
          <div>
            <span>Active 라이선스</span>
            <span>
              <Chip label={countValues.activeCount} icon={<AssignmentTurnedInIcon />} color='success' style={{backgroundColor:'rgb(76,175,80)'}}/> 개
            </span>
          </div>
          <Divider />
          <div>
            <span>유지보수 만료 예정</span>
            <span>
              <Chip label={countValues.willExpireConunt} icon={<AccessTimeIcon />} color='error' style={{backgroundColor:'rgb(244,67,54)'}} /> 개
            </span>
          </div>
          <Divider />
          <div>
            <span>유지보수 만료</span>
            <span>
              <Chip label={countValues.expiredCount} icon={<AutoDeleteIcon />} color='error' style={{backgroundColor:'rgb(0,188,212)'}} /> 개
            </span>
          </div>
        </div>
      ) : (
        <SkeletonSmallBox />
      )}
    </>
  );
}

export default ActiveAndExpiredLicenseCount