import axios from 'axios';
import { GetHeaders } from './Authentication';
import { ServerUrl, CreateMemoPath, GetMemoListPath } from '../conf/ServerInfo';

interface CreateMemoProps {
  companyKey: string;
  memo: string;
}

interface GetMemoListProps {
  companyKey: string;
}

export const CreateMemo = async (memoValues: CreateMemoProps) => {
  const params = memoValues;
  
  const config = GetHeaders();
  
  return await axios.post(`${ServerUrl}${CreateMemoPath}`, params, config)
};

export const GetMemoList = async (companyKey: GetMemoListProps) => {
  const params = companyKey;

  const config = GetHeaders();
  
  return await axios.post(`${ServerUrl}${GetMemoListPath}`, params, config)
};  