export const ServerScheme = 'https://';
export const ServerHosts = 'cs.officehard.com/';
export const ServerUrl = `${ServerScheme}${ServerHosts}`;

export const SignInPath = 'api/auth/signin/';
export const SignOutPath = 'api/auth/signout/';

export const CreateCompanyPath = 'api/company/create/';
export const GetCompanyCountPath = 'api/company/count/';
export const GetCompanyListPath = 'api/company/list/';
export const GetCompanyInfoPath = 'api/company/info/';
export const SetCompanyInfoPath = 'api/company/update/';
export const DeleteCompanyInfoPath = 'api/company/delete/';

export const CreateLicensePath = 'api/license/create/';
export const GetLicenseListPath = 'api/license/list/';
export const GetLicenseInfoPath = 'api/license/info/';
export const SetLicenseInfoPath = 'api/license/update/';
export const SetLicenseEnablePath = 'api/license/enable/';
export const DownloadLicensePath = 'api/license/download/';

export const CreateMemoPath = 'api/license/memo/create/';
export const GetMemoListPath = 'api/license/memo/list/';

export const CreateAccountPath = 'api/member/create/';
export const GetAccountCountPath = 'api/member/count/';
export const GetAccountListPath = 'api/member/list/';
export const GetAccountInfoPath = 'api/member/info/';
export const SetAccountInfoPath = 'api/member/update/';

export const GetProfileInfoPath = 'api/user/info/';
export const SetProfileInfoPath = 'api/user/update/';

export const ActiveAndExpiredLicenseCountPath = 'api/dashboard/license_active_count/';
export const WillExpireCompanyListPath = 'api/dashboard/license_will_expire_list/';
export const CompanyStatePieChartPath = 'api/dashboard/company_type_count/';
export const LicenseVersionTreemapChartPath = 'api/dashboard/package_version_count/';
export const RegisterCountLineChartPath = 'api/dashboard/license_create_by_year_count/';
export const LicenseUserCountBarChartPath = 'api/dashboard/license_top_user_list/';