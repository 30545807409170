import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { CreateCompany, GetCompanyInfo, SetCompanyInfo } from '../../../libraries/Company';

interface RegisterCompanyDialogProps {
  openRegisterCompanyDialog: boolean;
  handleClickRegisterCompanyDialog: () => void;
  modifyOn?: string;
  companyKey?: string;
}

function RegisterCompanyDialog({openRegisterCompanyDialog, handleClickRegisterCompanyDialog, modifyOn, companyKey}: RegisterCompanyDialogProps) {
  const id = "daum-postcode";
  const src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const postcodeRef = useRef<HTMLDivElement | null>(null);

  const [companyKeyParameter, setCompanyKeyParameter] = useState('');
  const [open, setOpen] = useState(false);

  const initialValues = {
    name: '',
    companyType: 0,
    companyPurpose: 0,
    clientName: '',
    clientPhoneFirst: '',
    clientPhoneSecond: '',
    clientEmail: '',
    addressMain: '',
    addressSub: '',
    managerName: '',
    memo: ''
  }
  const [values, setValues] = useState(initialValues)  ;

  const initialFieldError = {
    name: {
      error: false,
      lengthError : false
    },
    companyType: {
      error: false
    },
    companyPurpose: {
      error: false
    },
    addressMain : {
      error : false,
      lengthError : false
    },
    addressSub : {
      error : false,
      lengthError : false
    },
    clientName : {
      error : false,
      lengthError : false
    },
    clientEmail: {
      error: false,
      lengthError : false
    },
    clientPhoneFirst: {
      error: false
    },
    clientPhoneSecond: {
      error: false
    },
    managerName : {
      error : false,
      lengthError : false
    },
    memo : {
      error : false,
      lengthError : false
    },
    helper: {
      helperText: '필수값'
    },
    helperEmail: {
      helperText: '이메일 형식 오류'
    },
    helperPhone: {
      helperText: '전화번호 형식 오류'
    },
    helperMaxLength_100 : {
      helperText : '100자 이내로 입력해주세요.',
    },
    helperMaxLength_150 : {
      helperText : '150자 이내로 입력해주세요.',
    },
    helperMaxLength_1024 : {
      helperText : '1024자 이내로 입력해주세요.',
    }
  }
  const [fieldError, setFieldError] = useState(initialFieldError);
  const companyType = [
    {
      label: '선택 없음',
      value: 0
    },
    {
      label: '기업',
      value: 1
    },
    {
      label: '관공서',
      value: 2
    },
    {
      label: '학교',
      value: 3
    }
  ];
  const companyPurpose = [
    {
      label: '선택 없음',
      value: 0
    },
    {
      label: '데모',
      value: 1
    },
    {
      label: '납품',
      value: 3
    },
    {
      label: '테스트',
      value: 4
    }
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClickRegisterCompanyDialog();
  };

  const handleInputValueValidation = () => {
    if (values.name.trim() === '') {
      setFieldError({...fieldError, name: {error: true, lengthError: false}});
      return false;
    }

    if(values.name.length > 100){
      setFieldError({...fieldError, name: {error: true, lengthError: true}});
      return false;
    }

    if (values.companyType === 0) {
      setFieldError({...fieldError, companyType: {error: true}});
      return false;
    }
    if (values.companyPurpose === 0) {
      setFieldError({...fieldError, companyPurpose: {error: true}});
      return false;
    }

    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.clientEmail !== '' && !values.clientEmail.match(emailFormat)) {
      setFieldError({...fieldError, clientEmail: {error: true, lengthError: false}});
      return false;      
    }

    if(values.clientEmail.length > 150){
      setFieldError({...fieldError, clientEmail: {error: true, lengthError: true}});
      return false;
    }

    if(values.clientName.length > 100){
      setFieldError({...fieldError, clientName: {error: true, lengthError: true}});
      return false;
    }

    const phoneFormat = /^(\d{2,3})[-](\d{3,4})[-](\d{4})$/;
    if (values.clientPhoneFirst !== '' && !values.clientPhoneFirst.match(phoneFormat)) {
      setFieldError({...fieldError, clientPhoneFirst: {error: true}});
      return false;      
    }

    if (values.clientPhoneSecond !== '' && !values.clientPhoneSecond.match(phoneFormat)) {
      setFieldError({...fieldError, clientPhoneSecond: {error: true}});
      return false;      
    }

    if(values.addressMain.length > 150){
      setFieldError({...fieldError, addressMain: {error : true, lengthError: true}});
      return false;
    }

    if(values.addressSub.length > 150){
      setFieldError({...fieldError, addressSub: {error : true, lengthError: true}});
      return false;
    }

    if(values.managerName.length > 100){
      setFieldError({...fieldError, managerName : {error: true, lengthError: true}});
      return false;
    }

    if(values.memo.length > 1024){
      setFieldError({...fieldError, memo: {error : true, lengthError: true}});
      return false;
    }

    return true;
  }

  const handleRegisterCompany = () => {
    if (handleInputValueValidation() === false) {
      return;
    }

    CreateCompany(values).then(result => {
      const resultData = result.data;
        if (resultData.result === 'S') {
          handleClose();
        }
    }).catch(error => {
      alert('Oops! Unknown error!');
      console.log(error.request.response);
    });
  };

  const handleModifyCompany = () => {
    if (handleInputValueValidation() === false) {
      return;
    }

    const modifyParameters = {
      companyKey: companyKeyParameter,
      ...values
    }

    SetCompanyInfo(modifyParameters).then(result => {
      const resultData = result.data;
      if (resultData.result === 'S') {
        handleClose();
      }
    }).catch(error => {
      alert('Oops! Unknown error!');
      console.log(error);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const checkNameField = /^(name|companyType|companyPurpose|clientEmail|clientPhoneFirst|clientPhoneSecond|addressMain|addressSub|memo|clientName|managerName)$/;
    if (name.match(checkNameField)) {
      setFieldError({...fieldError, [name]: {error: false}});
    }

    if (name === 'clientPhoneFirst' || name === 'clientPhoneSecond') {
      const phoneValue = value.replace(/[^0-9]/g, '').replace(/(^02|^0[0-9]{2})(\d{3,4})(\d{4})$/, '$1-$2-$3');
      setValues({ ...values, [name]: phoneValue });
    }else{
      setValues({ ...values, [name]: value });
    }
  };

  const openPostCodePopup = () => {
    window.daum?.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data) {
          if(data.userLanguageType === 'K'){
            if(data.userSelectedType === 'J'){
              setValues({ ...values, addressMain: data.jibunAddress });
            }else{
              setValues({ ...values, addressMain: data.roadAddress });
            }
          }else{
            if(data.userSelectedType === 'J'){
              setValues({ ...values, addressMain: data.jibunAddressEnglish });
            }else{
              setValues({ ...values, addressMain: data.roadAddressEnglish });
            }
          }
        }
      });
      postcode.open();
    });
  };

  useEffect(() => {
    const isAlready = document.getElementById(id);

    if (!isAlready) {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      document.body.append(script);
    }
  },[]);

  useEffect(() => {
    if (openRegisterCompanyDialog === true) {
      handleClickOpen();
    }
  },[openRegisterCompanyDialog]);

  useEffect(() => {
    if (modifyOn === 'Y' && companyKey) {
      setCompanyKeyParameter(companyKey);
      const companyObject = {companyKey: companyKey};
      GetCompanyInfo(companyObject).then(result => {
        const resultData = result.data;
        if (resultData.result === 'S') {
          setValues({
            name: resultData.info.name,
            companyType: resultData.info.companyType,
            companyPurpose: resultData.info.companyPurpose,
            clientName: resultData.info.clientName,
            clientPhoneFirst: resultData.info.clientPhoneFirst,
            clientPhoneSecond: resultData.info.clientPhoneSecond,
            clientEmail: resultData.info.clientEmail,
            addressMain: resultData.info.addressMain,
            addressSub: resultData.info.addressSub,
            managerName: resultData.info.managerName,
            memo: resultData.info.memo
          });
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },[modifyOn]);

  useEffect(() => {
    return() => setOpen(false);
  },[]);

  useEffect(() => {
    if (open === false) {
      setValues(initialValues);
      setFieldError(initialFieldError);
    }
  },[open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>업체 관리</DialogTitle>
        <DialogContent>
          <TextField 
            {...fieldError.name}
            {...fieldError.name.error === true ? ((fieldError.name.lengthError === true) ? fieldError.helperMaxLength_100 : fieldError.helper) : ''}
            autoFocus
            margin='dense'
            id='name'
            name='name'
            label='업체명'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.name}
          />
          <TextField
            {...fieldError.companyType}
            {...fieldError.companyType.error === true ? fieldError.helper : ''}
            select
            margin='dense'
            id='companyType'
            name='companyType'
            label='구분'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.companyType}
           >
            {companyType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            {...fieldError.companyPurpose}
            {...fieldError.companyPurpose.error === true ? fieldError.helper : ''}
            select
            margin='dense'
            id='companyPurpose'
            name='companyPurpose'
            label='상태'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.companyPurpose}
            >
            {companyPurpose.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>	  
          <TextField
            {...fieldError.clientName}
            {...fieldError.clientName.error === true ? fieldError.helperMaxLength_100 : ''}
            margin='dense'
            id='clientName'
            name='clientName'
            label='업체 담당자'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.clientName}
          />
          <TextField
            {...fieldError.clientPhoneFirst}
            {...fieldError.clientPhoneFirst.error === true ? fieldError.helperPhone : ''}
            margin='dense'
            id='clientPhoneFirst'
            name='clientPhoneFirst'
            label='업체 담당자 연락처 1'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.clientPhoneFirst}
          />
          <TextField
            {...fieldError.clientPhoneSecond}
            {...fieldError.clientPhoneSecond.error === true ? fieldError.helperPhone : ''}
            margin='dense'
            id='clientPhoneSecond'
            name='clientPhoneSecond'
            label='업체 담당자 연락처 2'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.clientPhoneSecond}
          />
          <TextField
            {...fieldError.clientEmail}
            {...fieldError.clientEmail.error === true ? ((fieldError.clientEmail.lengthError) ? fieldError.helperMaxLength_150 : fieldError.helperEmail) : ''}
            margin='dense'
            id='clientEmail'
            name='clientEmail'
            label='업체 담당자 이메일'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.clientEmail}
          />	
          <TextField
            {...fieldError.addressMain}
            {...fieldError.addressMain.lengthError ? fieldError.helperMaxLength_150 : ''}
            margin='dense'
            id='addressMain'
            name='addressMain'
            label='주소'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            onClick={openPostCodePopup}
            value={values.addressMain}
          />
          <TextField
            {...fieldError.addressSub}
            {...fieldError.addressSub.lengthError ? fieldError.helperMaxLength_150 : ''}
            margin='dense'
            id='addressSub'
            name='addressSub'
            label='상세 주소'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.addressSub}
          />		  	  
          <TextField
            {...fieldError.managerName}
            {...fieldError.managerName.lengthError ? fieldError.helperMaxLength_100 : ''}
            margin='dense'
            id='managerName'
            name='managerName'
            label='내부 담당자'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.managerName}
          />
          <TextField
            {...fieldError.memo}
            {...fieldError.memo.lengthError ? fieldError.helperMaxLength_1024 : ''}
            margin='dense'
            id='memo'
            name='memo'
            label='메모'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.memo}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='large'>취소</Button>
          <Button onClick={modifyOn === 'Y' ? handleModifyCompany : handleRegisterCompany} size='large'>적용</Button>
        </DialogActions>
      </Dialog>
      <div ref={postcodeRef}></div>
    </div>
  );
}

export default RegisterCompanyDialog