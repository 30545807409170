import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionStorage } from '../../libraries/Authentication';

interface PublicRouteProps {
	children: JSX.Element;
}

function PublicRoute({children}: PublicRouteProps) {
  try {
    const accessToken = getSessionStorage();
    return accessToken ? <Navigate to='/dashboard' /> : children;
  } catch {
    return <Navigate to='/' />;
  }
}

export default PublicRoute