import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function SkeletonLargeBox() {
  return (
    <Stack spacing={1}>
      <Skeleton variant='rectangular' width={672} height={400} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm:'100'} }} />
    </Stack>
  );
}

export default SkeletonLargeBox