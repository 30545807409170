import React, { useState, useEffect } from 'react';
import { BarChart } from '@toast-ui/react-chart';
import { GetLicenseUserCountBarChart } from '../../libraries/Dashboard';
import SkeletonLargeBox from './common/SkeletonLargeBox';

interface LicenseUserCountBarChartProps {
  label: string;
  count: number;
}

interface LicenseUserCountBarChartDataProps {
  name: string;
  data: number[];
}

function LicenseUserCountBarChart() {
  const [values, setValues] = useState([{name: '', data: [0]}]);
  const [completeLoading, setCompleteLoading] = useState(false);

  useEffect(() => {
    GetLicenseUserCountBarChart().then(result => {
      const resultData = result.data;
      let tempArrayData:LicenseUserCountBarChartDataProps[] = [];
      if (resultData.result === 'S') {
        resultData.list.map((list: LicenseUserCountBarChartProps) => {
          tempArrayData.push({name: list.label, data: [list.count]});
        });
        setCompleteLoading(true);
        setValues(tempArrayData);
      }
    }).catch(error => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    return() => setCompleteLoading(true);
  },[]);

  const data = {
    categories: ['업체'],
    series: values,
  };
  const options = {
    xAxis: {
      title: '명',
    },
    chart: {
      width: 670,
      height: 340
    },
    exportMenu: {
      visible: false,
    },
    legend: {
      visible: true,
    },
    lang: {
      noData: '😭No Data!!😭',
    },
  };

  return (
    <>
      {completeLoading !== false ? (
        <div>
          <div className='dashboard-list-title'>최대 유저 수</div>
          <BarChart data={data} options={options} />
        </div>
      ) : (
        <SkeletonLargeBox />
      )}  
    </>
  );
}

export default LicenseUserCountBarChart