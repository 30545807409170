import React, { useState, useEffect } from 'react';
import { LineChart } from '@toast-ui/react-chart';
import { GetRegisterCountLineChart } from '../../libraries/Dashboard';
import SkeletonLargeBox from './common/SkeletonLargeBox';

interface RegisterCountLineChartProps {
  label: string;
  count: number;
}

function RegisterCountLineChart() {
  const [indexValues, setIndexValues] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [completeLoading, setCompleteLoading] = useState(false);

  useEffect(() => {
    GetRegisterCountLineChart().then(result => {
      const resultData = result.data;
      let tempArrayIndexData: string[] = [];
      let tempArrayValueData: number[] = [];
      if (resultData.result === 'S') {
        resultData.list.map((list: RegisterCountLineChartProps) => {
          tempArrayIndexData.push(list.label);
          tempArrayValueData.push(list.count);
        });
        setIndexValues(tempArrayIndexData.reverse());
        setValues(tempArrayValueData.reverse());
        setCompleteLoading(true);
      }
    }).catch(error => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    return() => setCompleteLoading(true);
  },[]);

  const data = {
    categories: indexValues,
    series: [
      {
        name: 'Count',
        data: values,
      }
    ]
  };
  const options = {
    chart: {
      width: 660,
      height: 340,
    },
    theme: {
      series: {
        colors:['#00bd9f'],
      },
    },
    exportMenu: {
      visible: false,
    },
    legend: {
      visible: false,
    },
    lang: {
      noData: '😭No Data!!😭',
    },
  };

  return (
    <>
      {completeLoading !== false ? (
        <div>
          <div className='dashboard-list-title'>등록 건 수</div>
          <LineChart data={data} options={options}/>
        </div>
      ) : (
        <SkeletonLargeBox />
      )}
    </>
  );
}

export default RegisterCountLineChart