import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';

interface CompanyListsProps {
  licensesSum: any;
  companyKey: string;
  name: string;
  managerName: string;
  regDate: string;
  modDate: string;
};

interface CompanyListContentProps {
  companyLists: {
    licensesSum: {
      sumDomain: number;
      sumUser: number;
      countLicense: number;
    };
    companyKey: string;
    name: string;
    managerName: string;
    regDate: string;
    modDate: string;
  }[];
  handleClick: (companyKey: string) => void;
};

function CompanyListContent({companyLists, handleClick}: CompanyListContentProps) {
  return (
    <div>
      {companyLists && companyLists.map((list: CompanyListsProps) => {
        const totalDomains = (list.licensesSum[0]?.sumDomain > 0) ? list.licensesSum[0].sumDomain : 0;
        const totalUsers = (list.licensesSum[0]?.sumUser > 0) ? list.licensesSum[0].sumUser.toLocaleString() : 0;
        const totalLicenses = (list.licensesSum[0]?.countLicense > 0) ? list.licensesSum[0].countLicense : 0;
        const regDate = list.regDate.split(' ');
        let iconTitle = '';
        let licenseIcon;
        if (totalLicenses > 0) {
          iconTitle = '라이선스 ' + totalLicenses + '개';
          licenseIcon = <CheckCircleOutlineIcon color='success' className='list-margin-right' />;
        } else {
          iconTitle = '라이선스 등록 전';
          licenseIcon = <RemoveCircleOutlineIcon color='error' className='list-margin-right' />;
        }
        return (
          <div key={list.companyKey} className='list-contents' onClick={() => handleClick(list.companyKey)}>
            <div className='list-content-name'>
              <span>
                <Tooltip title={iconTitle} placement="left-start">
                  {licenseIcon}
                </Tooltip>
              </span>
              <span className='list-content-name'>
                {list.name}
              </span>
            </div>
            <div className='list-content-manager'>{list.managerName}</div>
            <div className='list-content-domain'>{totalDomains}</div>
            <div className='list-content-user'>{totalUsers}</div>
            <div className='list-content-license'>{totalLicenses}</div>
            <div className='list-content-regdate'>{regDate[0]}</div>
          </div>
        );
      })}
    </div>
  );
}

export default CompanyListContent