import axios from 'axios';
import { ServerUrl, CreateAccountPath, GetAccountCountPath, GetAccountListPath, GetAccountInfoPath, SetAccountInfoPath } from '../conf/ServerInfo';
import { GetHeaders } from './Authentication';

interface GetAccountListProps {
  page?: number;
  limit?: number;
  sortName?: string;
  sortDirection?: string;
  searchText?: string;
};

interface CreateAccountProps {
  email: string;
  name: string;
  password: string;
  checkPassword: string;
}

interface GetAccountInfoProps {
  userKey: string;
}

interface SetAccountInfoProps {
  userKey: string;
  name: string;
  active: boolean;
}

export const GetAccountCount = async () => {
  const params = {};

  const config = GetHeaders();
  
  return await axios.post(`${ServerUrl}${GetAccountCountPath}`, params, config);
};

export const GetAccountList = async (accountValues: GetAccountListProps) => {
  const params = accountValues;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${GetAccountListPath}`, params, config);
};

export const CreateAccount = async (accountValues: CreateAccountProps) => {
  const params = accountValues;
  
  const config = GetHeaders();
  
  return await axios.post(`${ServerUrl}${CreateAccountPath}`, params, config);
};

export const GetAccountInfo = async (accountUserKey: GetAccountInfoProps) => {
  const params = accountUserKey;
  
  const config = GetHeaders();
  
  return await axios.post(`${ServerUrl}${GetAccountInfoPath}`, params, config);
};

export const SetAccountInfo = async (accountValues: SetAccountInfoProps) => {
  const params = accountValues;

  const config = GetHeaders();
  
  return await axios.put(`${ServerUrl}${SetAccountInfoPath}`, params, config);
};