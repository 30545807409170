import React, { useState, useEffect } from 'react';
import { PieChart } from '@toast-ui/react-chart';
import { GetCompanyStatePieChart } from '../../libraries/Dashboard';
import SkeletonSmallBox from './common/SkeletonSmallBox';

interface CompanyStatePieChartProps {
  label: string;
  count: number;
}

interface CompanyStatePieChartDataProps {
  name: string;
  data: number;
}

function CompanyStatePieChart() {
  const [values, setValues] = useState([{name: '', data: 0}]);

  useEffect(() => {
    GetCompanyStatePieChart().then(result => {
      const resultData = result.data;
      let tempArrayData:CompanyStatePieChartDataProps[] = [];
      if (resultData.result === 'S') {
        resultData.list.map((list: CompanyStatePieChartProps) => {
          tempArrayData.push({name: list.label, data: list.count});
        });
        setValues(tempArrayData);
      }
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const data = {
    categories: ['업체 구분'],
    series: values,
  };
  const options = {
    chart: {
      title: '설치 구분',
      width: 320,
      height: 320,
    },
    series: {
      dataLabels: {
        visible: true,
        pieSeriesName: {
          visible: true,
        },
      },
    },
    theme: {
      series: {
        colors: ['#41b2e6','#20cbc2','#074d81'],
      }
    },
    exportMenu: {
      visible: false,
    },
    legend: {
      visible: false,
    },
    lang: {
      noData: '😭No Data!!😭',
    },
  };

  return (
    <div>
      {values[0].name !== '' ? (
        <PieChart data={data} options={options} />
        ) : (
          <SkeletonSmallBox />
        )
      }
    </div>
  );
}

export default CompanyStatePieChart