import React from 'react';
import Layout from './Layout';
import DashboardList from '../../components/dashboard/DashboardList';

function Dashboard() {
  return (
    <>
      <Layout children={<DashboardList />} />
    </>
  );
}

export default Dashboard