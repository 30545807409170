import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

interface ListPagingProps {
  totalPage: number;
  currentPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

function ListPaging({totalPage, currentPage, handleChange}: ListPagingProps) {
  return (
    <div className='list-paging'>
      <Stack spacing={2}>
        <Pagination count={totalPage} page={currentPage} onChange={handleChange} color='primary' />
      </Stack>
    </div>	  
  );
}

export default ListPaging