import React from 'react';

function NoDataSmallBox() {
  return (
    <div className='dashboard-list-small-no-data'>
      <div>😭No Data!!😭</div>
    </div>
  );
}

export default NoDataSmallBox