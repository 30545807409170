import React, { useState, useEffect } from 'react';
import { TreemapChart } from '@toast-ui/react-chart';
import { GetLicenseVersionTreemapChart } from '../../libraries/Dashboard';
import SkeletonSmallBox from './common/SkeletonSmallBox';

interface LicenseVersionTreemapChartProps {
  label: string;
  count: number;
}

interface LicenseVersionTreemapChartDataProps {
  label: string;
  data: number;
}

function LicenseVersionTreemapChart() {
  const [completeLoading, setCompleteLoading] = useState(false);
  const [values, setValues] = useState([{label: '', data: 0}]);

  useEffect(() => {
    GetLicenseVersionTreemapChart().then(result => {
      const resultData = result.data;
      let tempArrayData:LicenseVersionTreemapChartDataProps[] = [];
      if (resultData.result === 'S') {
        resultData.list.map((list: LicenseVersionTreemapChartProps) => {
          tempArrayData.push({label: list.label, data: list.count});
        });
        setCompleteLoading(true);
        setValues(tempArrayData);
      }
    }).catch(error => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    return() => setCompleteLoading(true);
  },[]);

  const data = {
    series: [
      {
        label: 'Documents',
        children: values,
      },
    ],
  }

  const options = {
    chart: {
      title:'버전 별 설치 건 수',
      width: 320,
      height: 320,
    },
    series: {
      dataLabels: {
        visible: true,
        useTreemapLeaf: true,
      },
    },
    theme: {
      series: {
        colors: ['#f10066'],//#f10066 채도 높은 빨간색 //#66ff66 녹색 //#dc395f 채도낮은 빨간색
      }
    },
    exportMenu: {
      visible: false,
    },
    lang: {
      noData: '😭No Data!!😭',
    },
  };

  return (
    <>
    {completeLoading !== false ? (
      <div>
        <TreemapChart data={data} options={options} />  
      </div>
    ) : (
      <SkeletonSmallBox />
    )}
    </>
  );
}

export default LicenseVersionTreemapChart