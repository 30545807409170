import React, { useState, useEffect } from 'react';
import { GetCompanyList } from '../../../libraries/Company';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CompanyListColumn from './CompanyListColumn';
import CompanyListContent from './CompanyListContent';
import ListPaging from '../../common/ListPaging';
import RegisterCompanyDialog from '../common/RegisterCompanyDialog';
import CompanyListSkeleton from './CompanyListSkeleton';

function CompanyList() {
  const [companyCounts, setCompanyCounts] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [searchText, setSearchText] = useState({searchText: ''});
  const [searchLoading, setSearchLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [companyListParams, setCompanyListParams] = useState({
    page: 1,
    limit: limit,
    sortName: 'regDate',
    sortDirection: 'desc'
  });
  const [companyLists, setCompanyLists] = useState([{
    licensesSum: {
      sumDomain: 0, 
      sumUser: 0, 
      countLicense: 0
    },
    companyKey: '',
    name: '',
    managerName: '',
    regDate: '',
    modDate: ''
  }]);
  const navigate = useNavigate();
  const [openRegisterCompanyDialog, setOpenRegisterCompanyDialog] = useState(false);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    setCompanyListParams({...companyListParams, page: value});
  };

  const handleClick = (companyKey: string) => {
    const navigateValue = `/companydetail/${companyKey}`;
    navigate(navigateValue);
  }

  const handleClickRegisterCompanyDialog = () => {
    if (openRegisterCompanyDialog === true) {
      setOpenRegisterCompanyDialog(false);
    } else {
      setOpenRegisterCompanyDialog(true);
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setCompleteLoading(true);
      setSearchLoading(!searchLoading);
    }
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText({searchText: event.target.value});
  }

  useEffect(() => {
    GetCompanyList(companyListParams, searchText).then(result => {
      const resultData = result.data;
      setCompleteLoading(true);
      setCompanyCounts(resultData.totalCount);
      setTotalPage(Math.ceil(resultData.totalCount / limit));
      setCompanyLists(resultData.list);
    }).catch(error => {
      console.log(error.request.response);
    });
  },[currentPage, openRegisterCompanyDialog, searchLoading]);

  useEffect(() => {
   return() => {
     handleClickRegisterCompanyDialog();
     setCompleteLoading(true);
   }
  },[]);

  return (
    <div className='list-wrapper'>
      {completeLoading !== false ? (
          <>
            <div className='list-etc'>
              <Button variant="outlined" onClick={handleClickRegisterCompanyDialog}>업체 추가</Button>
              <TextField
                id='search'
                name='search'
                label='검색'
                variant='outlined'
                size='small'
                onKeyPress={handleKeyPress}
                onChange={handleChangeSearch}
              />
            </div>
            <Paper elevation={10} className='paper-style'>
              <CompanyListColumn />
              <CompanyListContent companyLists={companyLists} handleClick={handleClick} />
              <ListPaging totalPage={totalPage} currentPage={currentPage} handleChange={handleChange} />
            </Paper>
          </>
        ) : (
          <CompanyListSkeleton />
        )
      }
      <RegisterCompanyDialog 
        openRegisterCompanyDialog={openRegisterCompanyDialog} 
        handleClickRegisterCompanyDialog={handleClickRegisterCompanyDialog} 
      />
    </div>
  );
}

export default CompanyList