import React from 'react';
import Layout from './Layout';
import CompanyDetail from '../../components/company/detail/CompanyDetail';

function License() {
  return (
    <>
      <Layout children={<CompanyDetail />} />
    </>
  );
}

export default License