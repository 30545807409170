import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GetProfileInfo, SetProfileInfo } from '../../../libraries/Profile';

interface ModifyUserProfileDialogProps {
  openModifyUserProfileDialog: boolean;
  handleClickModifyUserProfileDialog: () => void;
}

function ModifyUserProfileDialog({openModifyUserProfileDialog, handleClickModifyUserProfileDialog}: ModifyUserProfileDialogProps) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ 
    name: '',
    password: '',
    newPassword: '',
    checkNewPassword: '',
  });
  const initialFieldError = {
    name: {
      error: false
    },
    password: {
      error: false
    },
    newPassword: {
      error: false
    },
    checkNewPassword: {
      error: false
    },
    helper: {
      helperText: '필수값'
    },
    requireHelper :{
      helperText : '필수값'
    },
    nameSizeHelper : {
      helperText : '100글자 이내로 입력하세요.'
    },
    IncorrectHelper : {
      helperText : '비밀번호가 다릅니다.'
    },
    pwdSizeHelper : {
      helperText : '8자리 이상 입력하세요.'
    },
    InvalidHelper : {
      helperText : '기존의 비밀번호가 올바르지 않습니다.'
    }
  }
  const [fieldError, setFieldError] = useState(initialFieldError);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const checkNameField = /^(name|password|newPassword|checkNewPassword)$/;
    if (name.match(checkNameField)) {
      fieldError.helper.helperText = fieldError.requireHelper.helperText;
      setFieldError({...fieldError, [name]: {error: false}});
    }
    setValues({ ...values, [name]: value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClickModifyUserProfileDialog();
  };

  const handleModifyUserProfile = () => {
    if (values.name.trim() === '') {
      setFieldError({...fieldError, name: {error: true}});
      return false;
    }
    if (values.name.length > 100) {
      setFieldError({...fieldError,
        name: {error: true},
        helper: {helperText: fieldError.nameSizeHelper.helperText}});
      return false;
    }
    if (values.password.trim() === '') {
      setFieldError({...fieldError, password: {error: true}});
      return false;
    }
    if (values.password.length < 8) {
      setFieldError({...fieldError,
        password: {error: true},
        helper: {helperText: fieldError.pwdSizeHelper.helperText}});
      return false;
    }
    if (values.newPassword.trim() === '') {
      setFieldError({...fieldError, newPassword: {error: true}});
      return false;
    }
    if (values.newPassword.length < 8) {
      setFieldError({...fieldError,
        newPassword: {error: true},
        helper: {helperText: fieldError.pwdSizeHelper.helperText}});
      return false;
    }
    if (values.checkNewPassword.trim() === '') {
      setFieldError({...fieldError, checkNewPassword: {error: true}});
      return false;
    }
    if (values.checkNewPassword.length < 8) {
      setFieldError({...fieldError,
        checkNewPassword: {error: true},
        helper: {helperText: fieldError.pwdSizeHelper.helperText}});
      return false;
    }
    if (values.newPassword !== values.checkNewPassword) {
      setFieldError({...fieldError,
        checkNewPassword: {error: true},
        helper: {helperText: fieldError.IncorrectHelper.helperText}});
      return false;
    }

    SetProfileInfo(values).then(result => {
      const resultData = result.data;
        if (resultData.result === 'S') {
          handleClose();
        }
    }).catch(error => {
      const parseResultCode = JSON.parse(error.request.responseText);

      if (parseResultCode.result === 105) {
        fieldError.helper.helperText = fieldError.InvalidHelper.helperText;
        setFieldError({...fieldError, password: {error: true}});
      }else if(parseResultCode.result === 106){
        alert('사용자 정보 업데이트가 실패했습니다.');
      }else{
        alert('Oops! Unknown error!');
        console.log(error);
      }
    });
  }

  useEffect(() => {
    if (openModifyUserProfileDialog === true) {
      handleClickOpen();
    }else{
      setValues({
        name: values.name,
        password: '',
        checkNewPassword: '',
        newPassword: ''
      })
      setFieldError(initialFieldError);
    }
  },[openModifyUserProfileDialog]);

  useEffect(() => {
    GetProfileInfo().then(result => {
      const resultData = result.data;
        if (resultData.result === 'S') {
          setValues({
            ...values, 
            name: resultData.info.name});
        }
    }).catch(error => {
      console.log(error);
    });
  },[]);

  useEffect(() => {
    return() => setOpen(true);
  },[]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>프로필 관리</DialogTitle>
        <DialogContent>
          <TextField
            {...fieldError.name}
            {...fieldError.name.error === true ? fieldError.helper : ''}
            margin='dense'
            id='name'
            name='name'
            label='이름'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.name}
          />
          <TextField
            {...fieldError.password}
            {...fieldError.password.error === true ? fieldError.helper : ''}          
            margin='dense'
            id='password'
            name='password'
            label='기존 비밀번호'
            fullWidth
            variant='outlined'
            type='password'
            onChange={handleChange}
          />
          <TextField
            {...fieldError.newPassword}
            {...fieldError.newPassword.error === true ? fieldError.helper : ''}          
            margin='dense'
            id='newPassword'
            name='newPassword'
            label='신규 비밀번호'
            fullWidth
            variant='outlined'
            type='password'
            onChange={handleChange}
          />
          <TextField
            {...fieldError.checkNewPassword}
            {...fieldError.checkNewPassword.error === true ? fieldError.helper : ''}
            margin='dense'
            id='checkNewPassword'
            name='checkNewPassword'
            label='신규 비밀번호 확인'
            fullWidth
            variant='outlined'
            type='password'
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='large'>취소</Button>
          <Button onClick={handleModifyUserProfile} size='large'>적용</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModifyUserProfileDialog