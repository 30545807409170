import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { GetLicenseInfo, SetLicenseInfo, DownoadLicense } from '../../../libraries/License';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TextFieldProps } from "@mui/material/TextField";

interface RegisterLicenseDialogProps {
  openModifyLicenseDialog: boolean;
  handleClickModifyLicenseDialog: () => void;
  companyKey: string;
  licenseKey: string;
  refreshLicenseList: () => void;
}

function RegisterLicenseDialog({openModifyLicenseDialog, handleClickModifyLicenseDialog, companyKey, licenseKey, refreshLicenseList}: RegisterLicenseDialogProps) {
  const [open, setOpen] = useState(false);
  const initialValues = {
    companyKey: '',
    licenseKey: '',
    license: '',
    companyCode: '',
    domainNumber: 0,
    userNumber: 0,
    duplexingNumber: 0,
    usePrivateFilter: false,
    useWinApp: false,
    useBackupApp: false,
    enable: false,
    expireDate: ''
  }
  const [values, setValues] = useState(initialValues);
  const initialFieldError = {
    companyCode: {
      error: false
    },
    domainNumber: {
      error: false
    },
    userNumber: {
      error: false
    },
    duplexingNumber: {
      error: false
    },
    expireDate : {
      error : false,
      afterError : false,
      invalidError : false
    },
    helper: {
      helperText: '필수값'
    },
    helperNumber: {
      helperText: '숫자만 입력',
    },
    helperAfterDate : {
      helperText: '현재날짜 이후를 입력하세요.',
    },
    helperInvalidDate : {
      helperText: '유효하지 않은 날짜입니다.',
    }
  }
  const [fieldError, setFieldError] = useState(initialFieldError);
  const booleanType = [
    {
      label: '사용',
      value: '1',
    },
    {
      label: '사용 안 함',
      value: '2',
    }
  ];
  const [selectValues, setSelectValues] = useState({
    usePrivateFilter: 2,
    useWinApp: 2,
    useBackupApp: 2,
    enable: 2,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClickModifyLicenseDialog();
  };

  const handleModifyLicense = () => {
    if (values.companyCode.trim() === '') {
      setFieldError({...fieldError, companyCode: {error: true}});
      return false;
    }
    if (isNaN(values.domainNumber) === true) {
      setFieldError({...fieldError, domainNumber: {error: true}});
      return false;
    }
    if (isNaN(values.userNumber) === true) {
      setFieldError({...fieldError, userNumber: {error: true}});
      return false;
    }
    if (isNaN(values.duplexingNumber) === true) {
      setFieldError({...fieldError, duplexingNumber: {error: true}});
      return false;
    }

    if(fieldError.expireDate.error === true){
      return false;
    }

    const modifyValues = {
      companyKey: values.companyKey,
      licenseKey: values.licenseKey,
      domainNumber: values.domainNumber,
      userNumber: values.userNumber,
      duplexingNumber: values.duplexingNumber,
      usePrivateFilter: values.usePrivateFilter,
      useWinApp: values.useWinApp,
      useBackupApp: values.useBackupApp,
      enable: values.enable,
      expireDate :  values.expireDate
    }

    SetLicenseInfo(modifyValues).then(result => {
      const resultData = result.data;
        if (resultData.result === 'S') {
          handleClose();
          refreshLicenseList();
        }
    }).catch(error => {
      alert('Oops! Unknown error!');
      console.log(error.request.response);
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const checkNameField = /^(companyCode|domainNumber|userNumber|duplexingNumber)$/;
    if (name.match(checkNameField)) {
      setFieldError({...fieldError, [name]: {error: false}});
    }

    setValues({ ...values, [name]: value });
  };

  const handleChangeSelectBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let selectValue = false;
    if (value === '1') {
      selectValue = true;
    }

    setValues({ ...values, [name]: selectValue });
    setSelectValues({ ...selectValues, [name]: value });
  };

  const handleClickDownloadLicense = () => {
    const downloadParams = {
      companyKey: companyKey,
      licenseKey: licenseKey
    };
    DownoadLicense(downloadParams).then(result => {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${values.companyCode}.ohv`);
      link.style.cssText = 'display:none';
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    }).catch(error => {
      console.log(error.request.response);
    });
  }

  useEffect(() => {
    if (openModifyLicenseDialog === true) {
      handleClickOpen();
    }else{
      setValues(initialValues);
      setFieldError(initialFieldError);
    }
  },[openModifyLicenseDialog]);

  useEffect(() => {
    setValues({ ...values, companyKey: companyKey, licenseKey: licenseKey });
  },[companyKey, licenseKey]);

  useEffect(() => {
    if (licenseKey) {
       const licenseInfoParameter = {
         companyKey: companyKey,
         licenseKey: licenseKey
       };

        GetLicenseInfo(licenseInfoParameter).then(result => {
        const resultData = result.data;
          if (resultData.result === 'S') {
            setValues({
              companyKey: resultData.info.companyKey,
              licenseKey: resultData.info.licenseKey,
              license: resultData.info.license,
              companyCode: resultData.info.companyCode,
              domainNumber: resultData.info.domainNumber,
              userNumber: resultData.info.userNumber,
              duplexingNumber: resultData.info.duplexingNumber,
              usePrivateFilter: resultData.info.usePrivateFilter,
              useWinApp: resultData.info.useWinApp,
              useBackupApp: resultData.info.useBackupApp,
              enable: resultData.info.enable,
              expireDate: resultData.info.expireDate,
            });
            const usePrivateFilterBooleanValue = (resultData.info.usePrivateFilter === true) ? 1 : 2;
            const useWinAppBooleanValue = (resultData.info.useWinApp === true) ? 1 : 2;
            const useBackupAppBooleanValue = (resultData.info.useBackupApp === true) ? 1 : 2;
            const enableValue = (resultData.info.enable === true) ? 1 : 2;

            setSelectValues({
              usePrivateFilter: usePrivateFilterBooleanValue,
              useWinApp: useWinAppBooleanValue,
              useBackupApp: useBackupAppBooleanValue,
              enable: enableValue
            });
          }
      }).catch(error => {
        console.log(error.request.response);
      });
    } 
  },[licenseKey]);  

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className='modify-license-title-download-wrapper'>
          <div>
            라이선스 관리 
            (<CopyToClipboard text={values.license} onCopy={() => alert('Copied!!')}>
              <span className='modify-license-clipboard-text'>{values.license}</span>
             </CopyToClipboard>)
          </div>
          <Tooltip title='라이선스 다운로드' placement='left'>
            <IconButton color='primary' sx={{ p: '10px' }} aria-label='directions' onClick={handleClickDownloadLicense}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <TextField 
            {...fieldError.companyCode}
            {...fieldError.companyCode.error === true ? fieldError.helper : ''}
            autoFocus
            margin='dense'
            id='companyCode'
            name='companyCode'
            label='업체 코드'
            fullWidth
            disabled
            variant='outlined'
            onChange={handleChange}
            value={values.companyCode}
          />
          <TextField
            {...fieldError.domainNumber}
            {...fieldError.domainNumber.error === true ? fieldError.helperNumber : ''}
            margin='dense'
            id='domainNumber'
            name='domainNumber'
            label='도메인'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.domainNumber}
            InputProps={{
              endAdornment: <InputAdornment position="end">개</InputAdornment>,
            }}
          />
          <TextField
            {...fieldError.userNumber}
            {...fieldError.userNumber.error === true ? fieldError.helperNumber : ''}          
            margin='dense'
            id='userNumber'
            name='userNumber'
            label='사용자'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.userNumber}
            InputProps={{
              endAdornment: <InputAdornment position="end">명</InputAdornment>,
            }}
          />
          <TextField
            {...fieldError.duplexingNumber}
            {...fieldError.duplexingNumber.error === true ? fieldError.helperNumber : ''}          
            margin='dense'
            id='duplexingNumber'
            name='duplexingNumber'
            label='이중화'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.duplexingNumber}
            InputProps={{
              endAdornment: <InputAdornment position="end">대</InputAdornment>,
            }}
          />
          <TextField
            select
            margin='dense'
            id='useWinApp'
            name='useWinApp'
            label='윈도우 탐색기'
            fullWidth
            variant='outlined'
            onChange={handleChangeSelectBox}
            value={selectValues.useWinApp}
           >
            {booleanType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin='dense'
            id='usePrivateFilter'
            name='usePrivateFilter'
            label='개인정보필터'
            fullWidth
            variant='outlined'
            onChange={handleChangeSelectBox}
            value={selectValues.usePrivateFilter}
           >
            {booleanType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin='dense'
            id='useBackupApp'
            name='useBackupApp'
            label='백업'
            fullWidth
            variant='outlined'
            onChange={handleChangeSelectBox}
            value={selectValues.useBackupApp}
           >
            {booleanType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label="유지보수 만료일"
                inputFormat="yyyy-MM-dd"
                mask={"____-__-__"}
                value={values.expireDate}
                minDate={new Date()}
                onChange={(newValue) => {
                  const changeDateFormat = moment(newValue).format('YYYY-MM-DD')

                  if(moment().isAfter(changeDateFormat)){
                    setFieldError({...fieldError, expireDate: {error: true, afterError: true, invalidError: false}});
                  }else if(!moment(changeDateFormat).isValid()){
                    setFieldError({...fieldError, expireDate: {error: true, afterError: false, invalidError: true}});
                  }else{
                    setFieldError({...fieldError, expireDate: {error: false, afterError: false, invalidError: false}});
                  }

                  setValues({...values, expireDate: changeDateFormat as string});
                }}
                renderInput={(params : TextFieldProps) =>
                    <TextField
                        {...params}
                        fullWidth
                        margin='dense'
                        error={fieldError.expireDate.error}
                        helperText={fieldError.expireDate.error ? ((fieldError.expireDate.afterError) ?
                            fieldError.helperAfterDate.helperText : fieldError.helperInvalidDate.helperText) : '' }
                    />}
            />
          </LocalizationProvider>
          <TextField
            select
            margin='dense'
            id='enable'
            name='enable'
            label='사용 여부'
            fullWidth
            variant='outlined'
            onChange={handleChangeSelectBox}
            value={selectValues.enable}
           >
            {booleanType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='large'>취소</Button>
          <Button onClick={handleModifyLicense} size='large'>적용</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RegisterLicenseDialog
