import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import { signOut, getUserType } from '../../libraries/Authentication';
import ModifyUserProfileDialog from './common/ModifyUserProfileDialog';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { GetProfileInfo } from '../../libraries/Profile';

function Account() {
  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState<string | null>('');
  const navigate = useNavigate();
  const [openModifyUserProfileDialog, setOpenModifyUserProfileDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSignOut = () => {
    signOut(navigate);
  };
  const handleClickModifyUserProfileDialog = () => {
    if (openModifyUserProfileDialog === true) {
      setOpenModifyUserProfileDialog(false);
    } else {
      setOpenModifyUserProfileDialog(true);
    }
  }
  const handleClickRedirectAddAcountPage = () => {
    const navigateValue = `/account`;
    navigate(navigateValue);
  }

  useEffect(() => {
    const user = getUserType();
    setUserType(user);
  },[]);

  useEffect(() => {
    GetProfileInfo().then(result => {
      const resultData = result.data;
        if (resultData.result === 'S') {
          setUserName(resultData.info.name);
        }
    }).catch(error => {
      console.log(error);
    });
  },[openModifyUserProfileDialog]);

  useEffect(() => {
    return() => handleClickModifyUserProfileDialog();
  },[]);
  
  return (
    <>
      <div className='layout-top-user' onClick={handleClick}>{userName} <ArrowDropDownIcon /></div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClickModifyUserProfileDialog}>
          <Avatar /> Profile
        </MenuItem>
        {userType === 'M' ? (
          <MenuItem onClick={handleClickRedirectAddAcountPage}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
        ) : (
          ''
        )
        }
        <Divider />
        <MenuItem onClick={handleClickSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
      <ModifyUserProfileDialog 
        openModifyUserProfileDialog={openModifyUserProfileDialog}
        handleClickModifyUserProfileDialog={handleClickModifyUserProfileDialog}
      />
    </>
  );
}

export default Account