import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CreateAccount } from '../../../libraries/Account';

interface RegisterAccountDialogProps {
  openRegisterAccountDialog: boolean;
  handleClickRegisterAccountDialog: () => void;
  refreshAccountList: () => void;
}

function RegisterAccountDialog({openRegisterAccountDialog, handleClickRegisterAccountDialog, refreshAccountList}: RegisterAccountDialogProps) {
  const [open, setOpen] = useState(false);

  const initialValues = {
    email: '',
    name: '',
    password: '',
    checkPassword: '',
  }
  const [values, setValues] = useState(initialValues);
  const initialFieldError = {
    email: {
      error: false
    },
    name: {
      error: false
    },
    password: {
      error: false
    },
    checkPassword: {
      error: false,
      diffPasswordError: false
    },
    helper: {
      helperText: '필수값'
    },
    helperPassword: {
      helperText: '비밀번호 다름'
    }
  }
  const [fieldError, setFieldError] = useState(initialFieldError);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClickRegisterAccountDialog();
  };

  const handleRegisterAccount = () => {
    if (values.email.trim() === '') {
      setFieldError({...fieldError, email: {error: true}});
      return false;
    }
    if (values.name.trim() === '') {
      setFieldError({...fieldError, name: {error: true}});
      return false;
    }
    if (values.password.trim() === '') {
      setFieldError({...fieldError, password: {error: true}});
      return false;
    }
    if (values.checkPassword.trim() === '') {
      setFieldError({...fieldError, checkPassword: {error: true, diffPasswordError: false}});
      return false;
    }    
    if (values.password.trim() !== values.checkPassword.trim()) {
      setFieldError({...fieldError, checkPassword: {error: true, diffPasswordError: true}});
      return false;
    }

    CreateAccount(values).then(result => {
      const resultData = result.data;
      if (resultData.result === 'S') {
        handleClose();
        refreshAccountList();
      }
    }).catch(error => {
      alert('Oops! Unknown error!');
      console.log(error.request.response);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const checkNameField = /^(email|name|password|checkPassword)$/;
    if (name.match(checkNameField)) {
      setFieldError({...fieldError, [name]: {error: false}});
    }

    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (openRegisterAccountDialog === true) {
      handleClickOpen();
    }else{
      setValues(initialValues);
      setFieldError(initialFieldError);
    }
  },[openRegisterAccountDialog]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>사용자 관리</DialogTitle>
        <DialogContent>
          <TextField 
            {...fieldError.email}
            {...fieldError.email.error === true ? fieldError.helper : ''}
            autoFocus
            margin='dense'
            id='email'
            name='email'
            label='email'
            fullWidth
            variant='outlined'
            onChange={handleChange}
          />
          <TextField
            {...fieldError.name}
            {...fieldError.name.error === true ? fieldError.helper : ''}
            margin='dense'
            id='name'
            name='name'
            label='이름'
            fullWidth
            variant='outlined'
            onChange={handleChange}
          />
          <TextField
            {...fieldError.password}
            {...fieldError.password.error === true ? fieldError.helper : ''}
            margin='dense'
            id='password'
            name='password'
            type='password'
            label='비밀번호'
            fullWidth
            variant='outlined'
            onChange={handleChange}
          />
          <TextField
            {...fieldError.checkPassword}
            {...fieldError.checkPassword.error === true ? ((fieldError.checkPassword.diffPasswordError === true) ? fieldError.helperPassword : fieldError.helper) : ''}
            margin='dense'
            id='checkPassword'
            name='checkPassword'
            type='password'
            label='비밀번호 확인'
            fullWidth
            variant='outlined'
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='large'>취소</Button>
          <Button onClick={handleRegisterAccount} size='large'>적용</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RegisterAccountDialog