import React from 'react';
import Paper from '@mui/material/Paper';
import '@toast-ui/chart/dist/toastui-chart.min.css';
import ActiveAndExpiredLicenseCount from './ActiveAndExpiredLicenseCount';
import CompanyStatePieChart from './CompanyStatePieChart';
import LicenseVersionTreemapChart from './LicenseVersionTreemapChart';
import RegisterCountLineChart from './RegisterCountLineChart';
import WillExpireCompanyList from './WillExpireCompanyList';
import LicenseUserCountBarChart from './LicenseUserCountBarChart';

function DashboardList() {
  return (
    <div className='list-wrapper'>
      <div className='dashboard-list-column'>
        <Paper elevation={5} className='paper-style-small-box'>
          <ActiveAndExpiredLicenseCount />
        </Paper>
        <Paper elevation={5} className='paper-style-small-box'>
          <WillExpireCompanyList />
        </Paper>
        <Paper elevation={5} className='paper-style-small-box'>
          <CompanyStatePieChart />
        </Paper>
        <Paper elevation={5} className='paper-style-small-box'>
          <LicenseVersionTreemapChart />
        </Paper>
      </div>
      <div className='dashboard-list-column'>
        <Paper elevation={5} className='paper-style-large-box'>
          <RegisterCountLineChart />
        </Paper>
        <Paper elevation={5} className='paper-style-large-box'>
          <LicenseUserCountBarChart />
        </Paper>
      </div>
    </div>
  );
}

export default DashboardList