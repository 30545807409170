import React from 'react';

function CompanyListColumn() {
  return (
    <div className='list-column'>
      <div>업체명</div>
      <div>담당자</div>
      <div>도메인</div>
      <div>사용자</div>
      <div>라이선스 수</div>
      <div>등록일</div>
    </div>
  );
}

export default CompanyListColumn