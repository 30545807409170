import React, { useState, useEffect } from  'react';
import { GetCompanyInfo } from '../../../libraries/Company';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

interface CompanyDetailInformationProps {
  companyKey: string
}

function CompanyDetailInformation(companyKey: CompanyDetailInformationProps) {
  const [completeLoading, setCompleteLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    enable: false,
    companyKey: '',
    name: '',
    companyType: 1,
    addressMain: '',
    addressSub: '',
    companyPurpose: 1,
    memo: '',
    clientName: '',
    clientPhoneFirst: '',
    clientPhoneSecond: '',
    clientEmail: '',
    managerName: '',
    regDate: '',
    modDate: '',
    apiKey: ''
  });
  
  const companyTypeArray = [
    {
      label: '기업',
      value: 1
    },
    {
      label: '관공서',
      value: 2
    },
    {
      label: '학교',
      value: 3
    }
  ];
  const companyPurposeArray = [
    {
      label: '데모',
      value: 1
    },
    {
      label: '납품',
      value: 3
    },
    {
      label: '테스트',
      value: 4
    }
  ];

  const [companyTypeString, setCompanyTypeString] = useState('');
  const [companyPurposeString, setCompanyPurposeString] = useState('');
  
  useEffect(() => {
    if (companyKey.companyKey) {
      GetCompanyInfo(companyKey).then(result => {
        setCompanyInfo(result.data.info);

        companyTypeArray.map((option) => {
          if (option.value === result.data.info.companyType) {
            setCompanyTypeString(option.label);
          }
        });
        companyPurposeArray.map((option) => {
          if (option.value === result.data.info.companyPurpose) {
            setCompanyPurposeString(option.label);
          }	
        });
        setCompleteLoading(true);
      }).catch(error => {
        console.log(error);
      });
    }
  },[companyKey]);

  useEffect(() => {
    return() => setCompleteLoading(true);
  },[]);

  return (
    <>
      {completeLoading !== false ? (
        <div className='detail-information'>
          <div className='detail-information-content'>
            <div className='detail-information-column'>업체명</div>
            <div>{companyInfo.name}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>구분</div>
            <div>{companyTypeString}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>상태</div>
            <div>{companyPurposeString}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>업체 담당자</div> 
            <div>{companyInfo.clientName}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>업체 담당자 전화 1</div>
            <div>{companyInfo.clientPhoneFirst}</div>
          </div>
          <div className='detail-information-content'>
          <div className='detail-information-column'>업체 담당자 전화 2</div>
          <div>{companyInfo.clientPhoneSecond}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>업체 담당자 Email</div>
            <div>{companyInfo.clientEmail}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>업체 주소</div>
            <div>{companyInfo.addressMain} {companyInfo.addressSub}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>내부 담당자</div>
            <div>{companyInfo.managerName}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>메모</div>
            <div className='detail-informaition-detail-memo'>{companyInfo.memo}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>수정일</div>
            <div>{companyInfo.regDate}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>등록일</div>
            <div>{companyInfo.modDate}</div>
          </div>
          <div className='detail-information-content'>
            <div className='detail-information-column'>API Key</div>
            <div>{companyInfo.apiKey}</div>
          </div>
        </div>
      ) : (
        <Stack spacing={1}>
          <Skeleton variant='rectangular' height={614} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm:'100'} }} />
        </Stack>
      )}
    </>
  );
}

export default CompanyDetailInformation