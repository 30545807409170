import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import CompanyDetailInformation from './CompanyDetailInformation';
import CompanyDetailLicenseList from './CompanyDetailLicenseList';
import CompanyDetailLog from './CompanyDetailLog';
import RegisterCompanyDialog from '../common/RegisterCompanyDialog';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CompanyDetail() {
  const params = useLocation();
  const [companyKey, setCompanyKey] = useState('');
  const [openRegisterCompanyDialog, setOpenRegisterCompanyDialog] = useState(false);
  const [modifyOn, setModifyOn] = useState('N');
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (params?.pathname !== '') {
      const paramCompanyKey = params.pathname.split('/');

      if (!paramCompanyKey[2]) {
        throw new Error('Invalid Parameter');
      }

      setCompanyKey(paramCompanyKey[2]);
    }
  },[params]);

  const handleClickRegisterCompanyDialog = () => {
    if (openRegisterCompanyDialog === true) {
      setOpenRegisterCompanyDialog(false);
      setModifyOn('N');
    } else {
      setOpenRegisterCompanyDialog(true);
      setModifyOn('Y');
    }
  }

  return (
    <div className='detail-wrapper'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="업체 관리" {...a11yProps(0)} />
          <Tab label="업무 로그" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className='detail-button'>
          <Button variant="outlined" onClick={handleClickRegisterCompanyDialog}>업체 수정</Button>
        </div>
        <Paper elevation={10} className='paper-style'>
          <CompanyDetailInformation companyKey={companyKey} />
        </Paper>
        <Paper elevation={10} className='paper-style-end'>
          <CompanyDetailLicenseList companyKey={companyKey} />
        </Paper>
        <RegisterCompanyDialog 
          openRegisterCompanyDialog={openRegisterCompanyDialog} 
          handleClickRegisterCompanyDialog={handleClickRegisterCompanyDialog}
          modifyOn={modifyOn}
          companyKey={companyKey}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper elevation={10} className='paper-style'>
          <CompanyDetailLog companyKey={companyKey} />
        </Paper>
      </TabPanel>
    </div>
  );
}

export default CompanyDetail