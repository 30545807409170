import React from 'react';
import Layout from './Layout';
import CompanyList from '../../components/company/list/CompanyList';

function License() {
  return (
    <>
      <Layout children={<CompanyList />} />
    </>
  );
}

export default License