import React, { useState, useEffect } from  'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { CreateMemo, GetMemoList } from '../../../libraries/Memo';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

interface CompanyDetailLogProps {
  companyKey: string
}

function CompanyDetailLog(companyKey: CompanyDetailLogProps) {
  const [message, setMessage] = useState<string>('');
  const [boxMessage, setBoxMessage] = useState([{licenseMemoKey: '', companyKey: '', email: '', memo: '', regDate: ''}]);
  const [reloadMessageList, setReloadMessageList] = useState(false);
  const [loading, setLoading] = useState(false);
  let dateDivider : string[] = [];

  const handleInputMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
	  setMessage(e.target.value);
  } 

  const handleClickSendMessage = () => {
    //setBoxMessage((prev:string[]) => [...prev, message]);
    const memoValues = {
      companyKey: companyKey.companyKey,
      memo: message
    };
    CreateMemo(memoValues).then(result => {
      const resultData = result.data;
      if (resultData.result === 'S') {
        setMessage('');
        setReloadMessageList(!reloadMessageList);
      }
    }).catch(error => {
      console.log(error.request.response);
    });
  }

  useEffect(() => {
    setLoading(false);
    GetMemoList(companyKey).then(result => {
      const resultData = result.data;
      if (resultData.result === 'S') {
        setBoxMessage(resultData.list);
      }
    }).catch(error => {
      console.log(error.request.response);
    });
    setLoading(true);
  },[companyKey, reloadMessageList]);

  useEffect(() => {
	  const scrollFocus = document.querySelector('#scrollFocus');
	  scrollFocus?.scrollIntoView(true);
  },[boxMessage]);

  return (
    <>
      {loading === true ? (
      <div>
        <div className='detail-log-message-list'>
          {boxMessage.map((list) => {
            const idFirstChar = list.email.charAt(0).toUpperCase();
            const regDate = list.regDate.split(' ');

            let isDivider = false;
            if(!dateDivider.includes(regDate[0])){
              dateDivider.push(regDate[0]);
              isDivider = true;
            }

            return (
                <div>
                  <div className='detail-log-date-divider'>
                    {
                      isDivider ? <Divider><Chip label={regDate[0]}/></Divider> : ''
                    }
                  </div>
                  <div key={list.licenseMemoKey} className='detail-log-message-content'>
                    {list.memo !== '' ? (
                        <>
                          <Avatar title={list.email} alt={list.email} sx={{ width: 24, height: 24}}>{idFirstChar}</Avatar>
                          <div className='detail-log-message-box'>
                            {list.memo}
                          </div>
                        </>
                    ) : ('')}
                    <span className='detail-log-message-date'>
                  {regDate[1]}
                </span>
                  </div>
                </div>

            );
          })}
          <div id="scrollFocus" style={{display:'hidden'}}></div>
        </div>
        <div className='detail-log-message-input'>
          <TextField 
            margin='dense'
            id='companyCode'
            name='companyCode'
            variant='outlined'
            fullWidth
            size='small'
            onChange={handleInputMessage}
            value={message}
            placeholder='로그를 입력하세요.'
          />
          <Button variant="contained" endIcon={<SendIcon />} onClick={handleClickSendMessage}>Send</Button>
        </div>
      </div>
      ) : (
        <Stack spacing={1}>
          <Skeleton variant='rectangular' height={370} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm:'100'} }} />
        </Stack>
      )
      }
    </>
  )
}

export default CompanyDetailLog