import axios from 'axios';
import { ServerUrl, SignInPath, SignOutPath } from '../conf/ServerInfo';
import { handlingError } from './Error';
import { NavigateFunction } from 'react-router';

interface SignInProps {
  email: string;
  password: string;
  setEmailError: React.Dispatch<React.SetStateAction<{error:boolean, helperText:string}>>;
}

export const getSessionStorage = () => {
  return sessionStorage.getItem('accessToken');
};

export const delSessionStorage = () => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('T');
  sessionStorage.removeItem('UE');
};

export const GetHeaders = () => {
  const accessToken = getSessionStorage();

  if ( accessToken === '') {
    throw new Error('Token not exist.');
  }

  return { 
    headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${accessToken}`   
    }
  };  
};

export const setSessionStorage = (accessToken: string) => {
  if (accessToken === '') {
    return false;
  } else {
    try {
      const token = accessToken.substring(0, accessToken.length - 1);
      const userType = accessToken.slice(-1);
      const afterTwentyFourHour = getAfterTwentyFourHourUnixTimeStamp();
      sessionStorage.setItem('accessToken', token);
      sessionStorage.setItem('T', userType);
      sessionStorage.setItem('UE', JSON.stringify(afterTwentyFourHour));
    } catch {
      return false;
    }
  }
};

export const signIn = async ({email, password, setEmailError}: SignInProps) => {
  if (email === '' || password === '') {
    throw new Error('Parameters are invalid.')
  }

  const params = {
    email: email,
    password: password
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }    
  };

  await axios.post(`${ServerUrl}${SignInPath}`, params, config)
  .then(response => {
    if (response.data.result === 'S') {
      setSessionStorage(response.data.accessToken);
      window.location.reload();
    } 
  })
  .catch(error => {
    const type = 'signin';
    const errorCode = error.response.data.result;
    const errorMessage = handlingError(type, errorCode);
    setEmailError({error: true, helperText: errorMessage});
  });
};

export const signOut = async (navigate: NavigateFunction) => {
  const navigateValue = `/`;

  const config = GetHeaders();

  await axios.get(`${ServerUrl}${SignOutPath}`, config)
  .then(response => {  
    if (response.data.result === 'S') {
      delSessionStorage();
      navigate(navigateValue);
    }
  })
  .catch(error => {
    console.log(error.response.result);
  });
};

export const getUserType = () => {
  return sessionStorage.getItem('T');
};

export const getAfterTwentyFourHourUnixTimeStamp = () => {
  const nowDate = Date.now();
  const AftertwentyFourHour = new Date(new Date(nowDate).getTime() + 60 * 60 * 24 * 1000);
  return new Date(AftertwentyFourHour).valueOf();
}

export const checkTokenExpireAfterTwentyForuHour = () => {
  const nowDateUnixTimeStamp: number = Date.now();
  const afterTwentyFourHourUnixTimeStamp: number = Number(sessionStorage.getItem('UE')); 
  if (nowDateUnixTimeStamp >= afterTwentyFourHourUnixTimeStamp) {
    delSessionStorage();
  }
}