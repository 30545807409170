import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { GetAccountList } from '../../../libraries/Account';
import AccountListColumn from './AccountListColumn';
import ListPaging from '../../common/ListPaging';
import AccountListContent from './AccountListContent';
import RegisterAccountDialog from '../common/RegisterAccountDialog';
import ModifyAccountDialog from '../common/ModifyAccountDialog';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function AccountList() {
  const [completeLoading, setCompleteLoading] = useState(false);
  const [accountCounts, setAccountCounts] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [accountListParams, setAccountListParams] = useState({
    page: 1,
    limit: limit,
    sortName: 'regDate',
    sortDirection: 'desc'
  });
  const [accountLists, setAccountLists] = useState([{
    userKey: '',
    email: '',
    name: '',
    isActive: false,
    lastLoginDate: '',
    regDate: '',
    modDate: ''
  }]);
  const [openRegisterAccountDialog, setOpenRegisterAccountDialog] = useState(false);
  const [openModifyAccountDialog, setOpenModifyAccountDialog] = useState(false);
  const [registerAccountRefreshList, setRegisterAccountRefreshList] = useState(false);
  const [accountUserKey, setAccountUserKey] = useState('');

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    setAccountListParams({...accountListParams, page: value});
  };

  const handleClickRegisterAccountDialog = () => {
    if (openRegisterAccountDialog === true) {
      setOpenRegisterAccountDialog(false);
    } else {
      setOpenRegisterAccountDialog(true);
    }
  }

  const handleClickModifyAccountDialog = (userKey: string) => {
    if (openModifyAccountDialog === true) {
      setOpenModifyAccountDialog(false);
      setAccountUserKey('');
    } else {
      setOpenModifyAccountDialog(true);
    }
    setAccountUserKey(userKey);
  }

  const refreshAccountList = () => {
    setRegisterAccountRefreshList(!registerAccountRefreshList);
  }

  useEffect(() => {
    GetAccountList(accountListParams).then(result => {
      const resultData = result.data;
      
      setAccountCounts(resultData.totalCount);
      setTotalPage(Math.ceil(resultData.totalCount / limit));
      setAccountLists(result.data.list);
      setCompleteLoading(true);
    }).catch(error => {
      console.log(error.request.response);
    });
  },[currentPage, registerAccountRefreshList]);

  return (
    <div className='list-wrapper'>
      {completeLoading !== false ? (
        <>
          <div className='list-etc'>
            <Button variant="outlined" onClick={handleClickRegisterAccountDialog}>사용자 추가</Button>
          </div>
          <Paper elevation={10} className='paper-style'>
            <AccountListColumn />
            <AccountListContent accountLists={accountLists} handleClickModifyAccountDialog={handleClickModifyAccountDialog} />
            <ListPaging totalPage={totalPage} currentPage={currentPage} handleChange={handleChange} />
          </Paper>
          <RegisterAccountDialog 
            openRegisterAccountDialog={openRegisterAccountDialog} 
            handleClickRegisterAccountDialog={handleClickRegisterAccountDialog}
            refreshAccountList={refreshAccountList}
          />
          <ModifyAccountDialog 
            openModifyAccountDialog={openModifyAccountDialog} 
            handleClickModifyAccountDialog={handleClickModifyAccountDialog}
            refreshAccountList={refreshAccountList}
            accountUserKey={accountUserKey}
          />
        </>
      ) : (
        <Stack spacing={1}>
          <div className='account-list-skeleton'>
            <Skeleton variant='rectangular' width={100} height={36} animation='wave' sx={{ bgcolor: 'grey.200' }} />
          </div>
          <Skeleton variant='rectangular' height={400} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm: '100'} }} />
        </Stack>
      )}
   </div>
  );
}

export default AccountList