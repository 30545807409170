import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Account from '../../components/layout/Account';
import { checkTokenExpireAfterTwentyForuHour } from '../../libraries/Authentication';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from 'react-responsive'

interface PageProps {
  children: JSX.Element;
}

interface MenuProps {
    isOpen : boolean;
}

function LeftMenu({isOpen} : MenuProps) {
    if(isOpen) {
        return(
            <div className="layout-left">
                <div>
                    <Link to='/dashboard'>Dashboard</Link>
                </div>
                <div>
                    <Link to='/company'>라이선스</Link>
                </div>
            </div>
        )
    }else{
        return null;
    }
}

function Layout({children}: PageProps) {
    const [leftMenu, setLeftMenu] = useState(false);
    const isMobile = useMediaQuery({maxWidth : 1200})

    useEffect(() => {
        checkTokenExpireAfterTwentyForuHour();
    }, []);

    useEffect(() => {
        isMobile === false ? setLeftMenu(true) : setLeftMenu(false)
    }, [isMobile])

  return (
    <div>
      <div className='layout-top'>
          <div className='layout-top-menu'>
              <MenuIcon fontSize='large' onClick={() => leftMenu ? setLeftMenu(false) : setLeftMenu(true)} />
          </div>
        <div className='layout-top-logo'>
          <Link to='/dashboard'>LaaS</Link>
        </div>
        <Account />
      </div>
      <div className='layout-content'>
          <LeftMenu isOpen={leftMenu} />
        <div className='layout-center'>
          {children}
        </div>
      </div>
	</div>
  );
}

export default Layout