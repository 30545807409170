import axios from 'axios';
import { ServerUrl, CreateLicensePath, GetLicenseListPath, GetLicenseInfoPath, SetLicenseInfoPath, SetLicenseEnablePath, DownloadLicensePath } from '../conf/ServerInfo';
import { GetHeaders } from './Authentication';

interface CreateLicenseProps {
  companyKey: string;
  companyCode: string; 
  domainNumber: number;
  userNumber: number;
  duplexingNumber: number;
  usePrivateFilter: boolean;
  useWinApp: boolean;
  useBackupApp: boolean;
};

interface GetLicenseProps {
  companyKey: string;
};

interface GetLicenseInfoProps {
  companyKey: string;
  licenseKey: string;    
};

interface SetLicenseInfoProps {
  licenseKey: string;
  companyKey: string;
  domainNumber?: number;
  userNumber?: number;
  duplexingNumber?: number;
  usePrivateFilter?: boolean;
  useWinApp?: boolean;
  useBackupApp?: boolean;
  expireDate?: string;
};

interface SetLicenseEnableProps {
  licenseKey: string;
  companyKey: string;
  enable: boolean;
};

export const CreateLicense = async (companyValues: CreateLicenseProps) => {
  const params = companyValues;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${CreateLicensePath}`, params, config)
};

export const GetLicenseList = async (companyKey: GetLicenseProps) => {
  const params = {
    companyKey: companyKey.companyKey
  };

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${GetLicenseListPath}`, params, config);
};

export const GetLicenseInfo = async (licenseParams: GetLicenseInfoProps) => {
  const params = {
    companyKey: licenseParams.companyKey,
    licenseKey: licenseParams.licenseKey
  };
   
  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${GetLicenseInfoPath}`, params, config);
};

export const SetLicenseInfo = async (licenseParams: SetLicenseInfoProps) => {
  const params = licenseParams;

  const config = GetHeaders();

  return await axios.put(`${ServerUrl}${SetLicenseInfoPath}`, params, config);
};

export const SetLicenseEnable = async (licenseParams: SetLicenseEnableProps) => {
  const params = licenseParams;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${SetLicenseEnablePath}`, params, config);
};

export const DownoadLicense = async (licenseParams: GetLicenseInfoProps) => {
  const params = licenseParams;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${DownloadLicensePath}`, params, config);
};