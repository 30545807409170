import React from 'react';
import Layout from './Layout';
import AccountList from '../../components/account/list/AccountList';

function Account() {
  return (
    <>
      <Layout children={<AccountList />} />
    </>
  );
}

export default Account