export const handlingError = (type: string, errorCode: number) => {
  let errorMessage;
  switch(type) {
    case 'signin': {
      errorMessage = signInError(errorCode); 
      break;
    }
    default: {
      errorMessage = 'Error';
    }
  }
  return errorMessage;
};

export const signInError = (errorCode: number) => {
  if (errorCode === 101) return '유효하지 않은 입력값입니다.';
  else if (errorCode === 102) return '아이디 또는 비밀번호가 올바르지 않습니다.';
  else if (errorCode === 103) return '접근이 차된단 IP입니다.';
  else if(errorCode === 104) return '사용자 연동정보가 존재하지 않습니다.';
  else if(errorCode === 105) return '로그인 토큰 생성에 실패했습니다.';
  else if(errorCode === 106) return '로그인 토큰 생성에 실패했습니다.';
  else return 'Error';
}