import axios from 'axios';
import { GetHeaders } from './Authentication';
import { ServerUrl, GetProfileInfoPath, SetProfileInfoPath } from '../conf/ServerInfo';

interface SetProfileInfoProps {
  name: string;
  password: string;
  newPassword: string;
  checkNewPassword: string;
}

export const GetProfileInfo = async () => {
  const config = GetHeaders();
  
  return await axios.get(`${ServerUrl}${GetProfileInfoPath}`, config)
};

export const SetProfileInfo = async (profileInfo: SetProfileInfoProps) => {
  const params = profileInfo;

  const config = GetHeaders();
  
  return await axios.put(`${ServerUrl}${SetProfileInfoPath}`, params, config)
};  