import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { GetWillExpireCompanyList } from '../../libraries/Dashboard';
import SkeletonSmallBox from './common/SkeletonSmallBox';
import NoDataSmallBox from './common/NoDataSmallBox';

interface WillExpireCompanyListProps {
  companyName: string;
  companyCode: string;
  expireDate: string;
}

interface WillExpireCompanyListDataProps {
  companyName: string;
  companyCode: string;
  expireDate: string;
}

function WillExpireCompanyList() {
  const [values, setValues] = useState([{companyName: '', companyCode: '', expireDate: ''}]);
  const [completeLoading, setCompleteLoading] = useState(false);

  useEffect(() => {
    GetWillExpireCompanyList().then(result => {
      const resultData = result.data;
      let tempArrayData: WillExpireCompanyListDataProps[] = [];

      if (resultData.result === 'S') {
        resultData.list.map((list: WillExpireCompanyListProps) => {
          tempArrayData.push({
            companyName: list.companyName, 
            companyCode: list.companyCode, 
            expireDate: list.expireDate
          });
        });
        setCompleteLoading(true);
        setValues(tempArrayData);
      }
    }).catch(error => {
      console.log(error);
    });
  },[]);

  useEffect(() => {
    return() => setCompleteLoading(true);
  },[]);

  return (
    <>
      {completeLoading !== false ? (
        <div>
          <div className='dashboard-list-title'>유지보수 만료 예정</div>
          <div className='dashboard-list-expired-company'>
          <Divider/>
            {values.map((list: WillExpireCompanyListProps) => {
              return (
                <div key={list.companyCode}>
                  {list.companyCode !== '' ? (
                    <>
                      <div>
                        <Tooltip title={`${list.companyName}(${list.companyCode})`} placement="top">
                          <span className='dashboard-list-recently-company-detail'>
                            <span className='dashboard-list-expired-color'>
                              {list.companyName}({list.companyCode})
                            </span>
                          </span>
                        </Tooltip>
                        <span>
                          <span className='dashboard-list-expired-text'>{list.expireDate}</span>
                          <span className='dashboard-list-expired-color'>까지</span>
                        </span>
                      </div>
                      <Divider />
                    </>
                  ) : (
                    <NoDataSmallBox />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        ) : (
          <SkeletonSmallBox />
        )}
    </>
  );
}

export default WillExpireCompanyList