import axios from 'axios';
import { ServerUrl, CreateCompanyPath, GetCompanyCountPath, GetCompanyListPath, GetCompanyInfoPath, SetCompanyInfoPath, DeleteCompanyInfoPath } from '../conf/ServerInfo';
import { GetHeaders } from './Authentication';

interface CreateCompanyProps {
  name: string;
  companyType: number; 
  companyPurpose: number;
  addressMain: string;
  addressSub: string;
  clientName: string;
  clientPhoneFirst: string;
  clientPhoneSecond: string;
  clientEmail: string;
  managerName: string;
  memo: string;
};

interface GetCompanyCountProps {
  searchText?: string;
};

interface GetCompanyListProps {
  page?: number;
  limit?: number;
  sortName?: string;
  sortDirection?: string;
  searchText?: string;
};

interface GetCompanyInfoProps {
  companyKey: string;
};

interface SetCompanyInfoProps {
  companyKey: string;
  name: string;
  companyType: number; 
  companyPurpose: number;
  addressMain: string;
  addressSub: string;
  memo: string;
  clientName: string;
  clientPhoneFirst: string;
  clientPhoneSecond: string;
  clientEmail: string;
  managerName: string;
};

export const CreateCompany = async (companyValues: CreateCompanyProps) => {
  const params = companyValues;

  const config = GetHeaders();
  
  return await axios.post(`${ServerUrl}${CreateCompanyPath}`, params, config);
};

export const GetCompanyCount = async (companyParam: GetCompanyCountProps) => {
  const params = companyParam;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${GetCompanyCountPath}`, params, config);
};

export const GetCompanyList = async (companyParams: GetCompanyListProps, searchText: GetCompanyCountProps) => {
  const params = companyParams;

  params.searchText = searchText.searchText;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${GetCompanyListPath}`, params, config);
};

export const GetCompanyInfo = async (companyParam: GetCompanyInfoProps) => {
  const params = companyParam;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${GetCompanyInfoPath}`, params, config);
};

export const SetCompanyInfo = async (companyParams: SetCompanyInfoProps) => {
  const params = companyParams;

  const config = GetHeaders();

  return await axios.put(`${ServerUrl}${SetCompanyInfoPath}`, params, config);
};

export const DeleteCompanyInfo = async (companyParam: GetCompanyInfoProps) => {
  const params = companyParam;

  const config = GetHeaders();

  return await axios.post(`${ServerUrl}${DeleteCompanyInfoPath}`, params, config);
};