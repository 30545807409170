import React, { useState, useCallback, useEffect } from 'react';
import { signIn } from '../../libraries/Authentication';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function Login({handleCheckClick = () => {}}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState({error: false, helperText: ''});
  const [passwordError, setPasswordError] = useState({error: false, helperText: ''});

  const onChangeEmail = useCallback(e => {
    setEmail(e.target.value);
  },[email]);

  const onChangePassword = useCallback(e => {
    setPassword(e.target.value);
  },[password]);

  const handleClickSignIn = () => {
    handleCheckClick?.();

    if (!email) {
      setEmailError({error: true, helperText: 'Input Email'});
    } 
    if (email && !password) {
      setPasswordError({error: true, helperText: 'Input Password'});
    } 

    if (email !== '' && password !== '') signIn({email, password, setEmailError});
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClickSignIn();
    }
  }

  useEffect(() => {
    if (email !== '') {
      setEmailError({error: false, helperText: ''});
    }
    if (password !== '') {
      setPasswordError({error: false, helperText: ''});
    }
  },[email, password]);

  return (
    <div className='login-main'>
      <div className='login-title'>License as a Service</div>
      <div className='login-contents'>
        <TextField
         {...emailError}
         id='outlined-basic'
         label='EMAIL'
         variant='outlined'
         role='input-email'
         onChange={onChangeEmail}
         value={email}
         onKeyPress={handleKeyPress}
         />
        <TextField
         {...passwordError}
         id='outlined-basic'
         label='PASSWORD'
         type='password'
         variant='outlined'
         role='input-password'
         onChange={onChangePassword}
         value={password}
         onKeyPress={handleKeyPress}
         />
        <Button variant="contained" onClick={handleClickSignIn} size='large' sx={{ textTransform: 'none'}}>Sign In</Button>
      </div>
    </div>
  );
}

export default Login