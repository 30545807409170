import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { GetAccountInfo, SetAccountInfo } from '../../../libraries/Account';

interface ModifyAccountDialogProps {
  openModifyAccountDialog: boolean;
  handleClickModifyAccountDialog: (key: string) => void;
  refreshAccountList: () => void;
  accountUserKey: string;
}

function ModifyAccountDialog({openModifyAccountDialog, handleClickModifyAccountDialog, refreshAccountList, accountUserKey}: ModifyAccountDialogProps) {
  const [open, setOpen] = useState(false);
  const initialValues = {
    userKey: '',
    email: '',
    name: '',
    active: false
  }
  const [values, setValues] = useState(initialValues);
  const initialFieldError = {
    email: {
      error: false
    },
    name: {
      error: false
    },
    password: {
      error: false
    },
    checkPassword: {
      error: false,
      diffPasswordError: false
    },
    helper: {
      helperText: '필수값'
    },
    helperPassword: {
      helperText: '비밀번호 다름'
    }
  }
  const [fieldError, setFieldError] = useState(initialFieldError);
  const booleanType = [
    {
      label: '사용',
      value: '1',
    },
    {
      label: '사용 안 함',
      value: '2',
    }
  ];
  const [selectValues, setSelectValues] = useState({active: 2});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValues({userKey: '', email: '', name: '', active: false});
    handleClickModifyAccountDialog('');
  };

  const handleModifyAccount = () => {
    if (values.email.trim() === '') {
      setFieldError({...fieldError, email: {error: true}});
      return false;
    }
    if (values.name.trim() === '') {
      setFieldError({...fieldError, name: {error: true}});
      return false;
    }

    SetAccountInfo(values).then(result => {
      const resultData = result.data;
      if (resultData.result === 'S') {
        handleClose();
        refreshAccountList();
      }
    }).catch(error => {
      alert('Oops! Unknown error!');
      console.log(error.request.response);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const checkNameField = /^(name)$/;
    if (name.match(checkNameField)) {
      setFieldError({...fieldError, [name]: {error: false}});
    }

    setValues({ ...values, [name]: value });
  };

  const handleChangeSelectBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let selectValue = false;
    if (value === '1') {
      selectValue = true;
    }

    setValues({ ...values, [name]: selectValue });
    setSelectValues({ ...selectValues, [name]: value });
  };

  useEffect(() => {
    if (openModifyAccountDialog === true) {
      handleClickOpen();
    }else{
      setValues(initialValues);
      setFieldError(initialFieldError);
    }
  },[openModifyAccountDialog]);

  useEffect(() => {
    if (accountUserKey) {
      const userKeyParams = {userKey: accountUserKey};
      GetAccountInfo(userKeyParams).then(result => {
        const resultData = result.data;
        if (resultData.result === 'S') {
          setValues({
            userKey: resultData.info.userKey,
            email: resultData.info.email,
            name: resultData.info.name,
            active: resultData.info.isActive
          });

          const activeValue = (resultData.info.isActive === true) ? 1 : 2;

          setSelectValues({
            active: activeValue
          });
        }
      }).catch(error => {
        console.log(error.request.response);
      });
    }
  },[accountUserKey]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>사용자 관리</DialogTitle>
        <DialogContent className='account-modify-dialog'>
          <TextField 
            {...fieldError.email}
            {...fieldError.email.error === true ? fieldError.helper : ''}
            autoFocus
            margin='dense'
            id='email'
            name='email'
            label='email'
            fullWidth
            variant='outlined'
            disabled
            onChange={handleChange}
            value={values.email}
          />
          <TextField
            {...fieldError.name}
            {...fieldError.name.error === true ? fieldError.helper : ''}
            margin='dense'
            id='name'
            name='name'
            label='이름'
            fullWidth
            variant='outlined'
            onChange={handleChange}
            value={values.name}
          />
          <TextField
            select
            margin='dense'
            id='active'
            name='active'
            label='사용 여부'
            fullWidth
            variant='outlined'
            onChange={handleChangeSelectBox}
            value={selectValues.active}
           >
            {booleanType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='large'>취소</Button>
          <Button onClick={handleModifyAccount} size='large'>적용</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModifyAccountDialog