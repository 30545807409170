import axios from 'axios';
import { 
  ServerUrl,
  ActiveAndExpiredLicenseCountPath,
  WillExpireCompanyListPath,
  CompanyStatePieChartPath,
  LicenseVersionTreemapChartPath,
  RegisterCountLineChartPath,
  LicenseUserCountBarChartPath
} from '../conf/ServerInfo';
import { GetHeaders } from './Authentication';

export const GetActiveAndExpiredLicenseCount = async () => {
  const config = GetHeaders();

  return await axios.get(`${ServerUrl}${ActiveAndExpiredLicenseCountPath}`, config);
};

export const GetWillExpireCompanyList = async () => {
  const config = GetHeaders();

  return await axios.get(`${ServerUrl}${WillExpireCompanyListPath}`, config);
};

export const GetCompanyStatePieChart = async () => {
  const config = GetHeaders();

  return await axios.get(`${ServerUrl}${CompanyStatePieChartPath}`, config);
};

export const GetLicenseVersionTreemapChart = async () => {
  const config = GetHeaders();

  return await axios.get(`${ServerUrl}${LicenseVersionTreemapChartPath}`, config);
};

export const GetRegisterCountLineChart = async () => {
  const config = GetHeaders();

  return await axios.get(`${ServerUrl}${RegisterCountLineChartPath}`, config);
};

export const GetLicenseUserCountBarChart = async () => {
  const config = GetHeaders();

  return await axios.get(`${ServerUrl}${LicenseUserCountBarChartPath}`, config);
};