import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function CompanyListSkeleton() {
  return (
    <Stack spacing={1}>
      <div className='list-skeleton'>
        <Skeleton variant='rectangular' width={100} height={36} animation='wave' sx={{ bgcolor: 'grey.200' }} />
        <Skeleton variant='rectangular' width={300} height={36} animation='wave' sx={{ bgcolor: 'grey.200' }} />
      </div>
      <Skeleton variant='rectangular' height={750} animation='wave' sx={{ bgcolor: 'grey.200', width: {sm:'100'} }} />
    </Stack>
  );
}

export default CompanyListSkeleton